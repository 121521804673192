<template>
  <b-container fluid >
    <b-row>
      <b-col md="12">
        <iq-card class="pt-2">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
            <h5>Name:&nbsp;<span>{{appData && appData.app_name}}</span></h5>
            <h6><small>App Type:&nbsp;<span>{{appData && appData.app_type}}</span></small></h6>
            <h6><small>Category:&nbsp;<span>{{appData && appData.app_category}}</span></small></h6>
            <p class="primary-color">
              {{user && user.user_name}} <br/>
              {{user && user.user_id}} <br/>
              {{user && user.user_email}} <br/>
            </p>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="resultFixListObj && resultFixListObj.length > 0">
                <b-table
                  hover
                  responsive
                  :items="resultFixListObj"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(action)="data">
                    <button class="actionIcon btn mt-1 primary-color" title="Delete Result" @click="deleteQuizAnswers(data.item.aprs_id)"><i class="fa fa-trash-o font-size-20 p-1"></i></button>
                    <button class="actionIcon btn mt-1 primary-color" title="Restore Reslt" @click="restoreQuizAnswers(data.item.aprs_id)"><i class="fa fa-refresh font-size-20 p-1"></i></button>
                  </template>

                  <template v-slot:cell(app_name)="data">
                    <p>{{data.item.app_name}}</p>
                    <b-badge v-if="data.item.deleted === 1" class="pointer ml-1" variant="primary mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Deleted
                    </b-badge>
                  </template>

                  <template v-slot:cell(aprs_id)="data">
                    <small>{{data.item.aprs_id.slice(0,15)}}....</small>
                    <i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.aprs_id)"></i>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import { QuizAnswers } from "../../../FackApi/api/quizAnswers"
import userPermission from "../../../Utils/user_permission"
export default {
  name: "AppResultListFix",
  data () {
    return {
      app_id: null,
      user_id: null,
      resultFixListObj: null,
      appData: null,
      user: null,

      apiName: "quiz_answers_aprs_list",
      cvCardTitle: "Fix App Results",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "App Result List Response",
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "App", key: "app_name", class: "text-left", sortable: true },
        { label: "Result Id", key: "aprs_id", class: "text-left align-text-top", sortable: true },
        { label: "Question Count", key: "question_count", class: "text-left align-text-top", sortable: true },
        { label: "Action", key: "action", class: "text-left align-text-top", sortable: true }
      ],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.getAllAvailableResults()
  },
  methods: {
    /**
     *
     */
    async getAllAvailableResults () {
      try {
        console.log({ app_id: this.$route.params.app_id, user_id: this.$route.params.user_id })
        let resultList = await QuizAnswers.quizAnswersAprsList(this, { app_id: this.$route.params.app_id, user_id: this.$route.params.user_id })
        if (!resultList.resp_status) {
          this.toastMsg = "Data not found"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        this.resultFixListObj = resultList.resp_data.data
        this.appData = resultList.app_data
        this.user = resultList.user_data
      }
      catch (err) {
        console.error("Exception in getAllAvailableResults and err: ", err)
      }
    },
    /**
     * deleteQuizAnswers
     */
    async deleteQuizAnswers (aprsId) {
      try {
        const deleteResp = await QuizAnswers.quizAnswersDelete(this, { aprs_id: aprsId, qns_id: "NA", app_id: this.appData.app_id, app_category: this.appData.app_category, gide_user_id: this.$route.params.user_id, fixing_result: true })
        if (!deleteResp.resp_status) {
          this.toastMsg = "Unable to delte answers"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        this.toastMsg = deleteResp.resp_msg
        this.toastVariant = "success"
        this.showToast = true
        this.getAllAvailableResults()
      }
      catch (err) {
        console.error("Exception in deleteQuizAnswers and err: ", err)
      }
    },
    /**
     * restoreQuizAnswers
     */
    async restoreQuizAnswers (aprsId) {
      try {
        let restoreQuizResp = await QuizAnswers.quizAnswersRestore(this, { aprs_id: aprsId, qns_id: "NA", app_id: this.appData.app_id, app_category: this.appData.app_category, gide_user_id: this.$route.params.user_id })
        if (!restoreQuizResp.resp_status) {
          this.toastMsg = "Unable to restore results"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        this.toastMsg = restoreQuizResp.resp_msg
        this.toastVariant = "success"
        this.showToast = true
        this.getAllAvailableResults()
      }
      catch (err) {
        console.error("Exception in restoreQuizAnswers and err: ", err)
      }
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    }
  }
}
</script>

<style>

</style>
